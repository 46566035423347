import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'radius-button',
  templateUrl: './radius-button.component.html',
  styleUrls: ['./radius-button.component.scss'],
})
export class RadiusButtonComponent implements OnInit {
  @Input() style: any;
  @Input() text: any;
  @Input() class: string;
  @Input() disabled: string;
  

  constructor() {}

  ngOnInit(): void {
  }

  
}
