import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PlatformService } from '@i-con/shared/common';


@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	@Input() screenclass: string = '';
	currentYear: number = new Date().getFullYear();
	email: string = "info@island-conference.com";
	public dialogRef: MatDialogRef<TermsAndConditionsComponent | PrivacyPolicyComponent>;

	constructor(
		public dialog: MatDialog,
		private _platformService: PlatformService) {
	}

	openTerms() {
		event?.preventDefault();
		this.dialogRef = this.dialog.open(TermsAndConditionsComponent, {
			maxHeight: '80vh',
			maxWidth: '85vw',
			autoFocus: false,
		});
	}

	openPrivacy() {
		event?.preventDefault();
		this.dialogRef = this.dialog.open(PrivacyPolicyComponent, {
			maxHeight: '80vh',
			maxWidth: '85vw',
			autoFocus: false,
		});
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
