import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { IPurchaseTicketForm } from '../../../core/_models/purchase-ticket-form.interface';
import { ITicketBundleType } from '../../../core/_models/ticket-bundle.interface';
import { PurchaseTicketFormGroupService } from '../../../core/_services/tickets/purchase-ticket-form-group/purchaseTicketFormGroup.service';
import { TicketBundleStateService } from '../../../core/_services/tickets/ticket-bundle-state.service';
import { ScriptService } from '../../services/script.service';
import { IFormField } from '@i-con/shared/common';
import { PlatformService } from '@i-con/shared/common';
import { IDiscountType } from '../../../core/_models/discount-type.interface';

declare let Securionpay: any;
declare let window: any;
declare let $: any;

interface ISecurionError {
  code?: string;
  message?: string;
  type?: string;
}

@Component({
  selector: 'i-con-select-payment-method-form',
  templateUrl: './select-payment-method-form.component.html',
  styleUrl: './select-payment-method-form.component.scss',
})
export class SelectPaymentMethodFormComponent implements OnInit, OnDestroy {
  @Input() fieldsDataPart4: IFormField[];
  @Input() submitted: boolean;
  @Input() ticketId: number;
  @Input() quantity: number;

  securionApi: any = null;
  components: any = null;
  number: any;
  cvc: any;
  expiry: any;
  updateInterval: any;
  purchaseTicketForm: FormGroup;
  controlValidations: FormControl[] = [];
  securionError: ISecurionError = {
    code: '',
    message: '',
    type: '',
  };
  selected_bundle_type: Observable<ITicketBundleType>;
  selected_bundle: ITicketBundleType;
  @Output() setPurchaseTicketForm = new EventEmitter();
  @Output() setPaymentError = new EventEmitter();
  constructor(
    private parentForm: FormGroupDirective,
    private purchaseTicketFormGroupService: PurchaseTicketFormGroupService,
    private ticketBundleStateService: TicketBundleStateService,
    private _platformService: PlatformService,
    private ref: ChangeDetectorRef,
    private readonly scriptService: ScriptService
  ) {
    if (this._platformService.isBrowser) {
      this.scriptService
        .load('securion')
        .then(() => {
          this.securionApi = new Securionpay('pk_live_ai4vbNoLmwWBR7XP4tVZgSV7');
          this.components = this.securionApi.createComponentGroup();
          this.number = this.components.createComponent('number').mount('#number');
          this.expiry = this.components.createComponent('expiry').mount('#expiry');
          this.cvc = this.components.createComponent('cvc').mount('#cvc');
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  ngOnInit() {
    this.purchaseTicketForm = this.parentForm.form;
    this.selected_bundle_type = this.ticketBundleStateService.selected_bundle_type$;

    this.selected_bundle_type.pipe().subscribe(selected_bundle_type => {
      this.selected_bundle = selected_bundle_type;
    });

    this.fieldsDataPart4.forEach(field => {
      const validations: ValidatorFn[] = [];

      Object.entries(field.validations).forEach(validation => {
        const key: keyof typeof Validators = validation[0] as keyof typeof Validators;

        // validation without parameters (eg. Validators.required)
        const validatorFunction = Validators[key] as () => ValidatorFn;

        // validation with parameters (eg. Validators.maxLength(10))
        const validatorFunctionWithParam = (Validators[key] as (param: number | string | RegExp) => ValidatorFn)(validation[1]);

        if (typeof validation[1] !== 'boolean') {
          validations.push(validatorFunctionWithParam);
        } else {
          validations.push(validatorFunction);
        }
      });

      const formControl = new FormControl('', validations);
      this.controlValidations.push(formControl);
    });

    this.purchaseTicketForm.addControl(
      'part4payment',
      new FormGroup({
        part4: new FormArray(this.controlValidations),
      })
    );

    this.updateInterval = setInterval(() => {
      this.ref.markForCheck();
    }, 250);
  }

  ngOnDestroy(): void {
    if (this._platformService.isBrowser) {
      this.scriptService.unloadScript('securion').catch((error: any) => {
        console.log(error);
      });
      window.Securionpay = undefined;
    }

    clearInterval(this.updateInterval);
  }

  checkErrors(fieldNumber: number, error: string) {
    const formGroup = this.purchaseTicketForm.get('part4payment');
    const mFormArray = formGroup?.get('part4') as FormArray;
    return this.purchaseTicketFormGroupService.checkErrors(mFormArray, error, fieldNumber);
  }

  get f() {
    const formGroup = this.purchaseTicketForm.get('part4payment');
    const mFormArray = formGroup?.get('part4') as FormArray;
    return mFormArray.controls;
  }

  resetErrors() {
    this.securionError = {
      code: '',
      message: '',
      type: '',
    };
    this.ref.markForCheck();
  }

  isValidForm() {
    if (this.number == null) return false;
    if (this.number?._empty || this.expiry?._empty || this.cvc?._empty || this.purchaseTicketForm.invalid) return false;
    if (this.number?._invalid || this.expiry?._invalid || this.cvc?._invalid || this.purchaseTicketForm.invalid) return false;
    return true;
  }

  submit(purchaseTicketForm: IPurchaseTicketForm) {
    this.resetErrors();
    this.submitted = true;
    if (this.purchaseTicketForm.invalid || !this.isValidForm()) {
      //this.paymentForm.reset();
      this.submitted = false;
      return;
    } else {
      this.securionApi
        .createToken(this.components, {
          cardholderName: this.purchaseTicketForm.value.cardholderName,
        })
        .then((token: any) => {
          const tokenRequest: any = {
            amount: this.getTicketPrice() * 100,
            currency: 'EUR',
            card: token.id,
          };
          
          this.securionApi
            .verifyThreeDSecure(tokenRequest)
            .then((token: any) => {
              const request = {
                tokenId: token.id,
                requireEnrolledCard: false,
                requireSuccessfulLiabilityShift: true,
              };

              this.setPurchaseTicketForm.emit({
                purchaseTicketForm,
                tokenRequest: request,
              });

              this.purchaseTicketFormGroupService.setInTransaction(false);
              this.purchaseTicketFormGroupService.setSubmitted(false);
              clearInterval(this.updateInterval);
            })
            .catch((error: ISecurionError) => {
              Object.assign(this.securionError, error);
              this.setPaymentError.emit({error});
              this.purchaseTicketFormGroupService.setInTransaction(false);
              this.purchaseTicketFormGroupService.setSubmitted(false);
              this.ref.markForCheck();
            });
        })
        .catch((error: ISecurionError) => {
          Object.assign(this.securionError, error);
          this.setPaymentError.emit({error});
          this.purchaseTicketFormGroupService.setInTransaction(false);
          this.purchaseTicketFormGroupService.setSubmitted(false);
          this.ref.markForCheck();
        });
    }
  }

  getTicketPrice() {
    let price = this.selected_bundle.ticket.price;

    if(this.selected_bundle.ticket.discounts.length > 0){
      this.selected_bundle.ticket.discounts.forEach((discount:IDiscountType) => {
        price = this.applyDiscount(price, discount);
      })
    }

    if(this.selected_bundle.discounts.length > 0){
      this.selected_bundle.discounts.forEach((discount:IDiscountType)=> {
        price = this.applyDiscount(price, discount);
      })
    }

    return price * this.selected_bundle.realQuantity;
  }

  applyDiscount(price: number, discount: any){
    const endsOn = new Date(Date.parse(discount.endsOn));
    if(new Date() < endsOn){
      return Math.round(price * (1 - discount.percentage) * 100)/100;
    }else{
      return price;
    }
  }
}
