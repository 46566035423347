<div class="newsletter-container" *ngIf="subData$ | async as subData">
    <a class="close-btn" (click)="closeDialog()"><img class="x-btn" src="https://cdn-images.island-conference.com/homepage/x-30.png" alt="Close button"></a>
    <div class="dialog-header">
        <img class="header-logo" src="https://cdn-images.island-conference.com/pageheader/header_logo.png" alt="Island Conference logo in white">
    </div>
    <div class="dialog-body" *ngIf="subData.subscribeData.response.statusCode != 201">
        <img class="calendar-icon" src="https://cdn-images.island-conference.com/homepage/calendar-newsletter.svg">
        <h2>
            Only a couple of weeks left until<br>
            i-Con 2024 makes its big reveal!
        </h2>
        <hr>
        <h3>While you wait...</h3>
        <p>
            Subscribe to our newsletter to join the waiting list and get updates on what Island Conference 2024 has to offer!
        </p>
        <div class="form-container">
            <form class="form" (ngSubmit)="onSubmit()" [formGroup]="subscribeForm">
                <h3>Fill in your info</h3>
                <div class="field-container">                    
                    <input type="text" class="input-field" placeholder="Email Address" formControlName="emailControl">
                    <div *ngIf="submitted && f['emailControl'].errors" class="invalid-feedback">
                        <div *ngIf="f['emailControl'].errors['required']">Email is required</div>
                        <div *ngIf="f['emailControl'].errors['email']">Please write a valid email</div>
                        <div *ngIf="f['emailControl'].errors['maxlength']">
                            A maximum of
                            {{ f["emailControl"].errors["maxlength"].requiredLength }} characters length is
                            required
                        </div>
                    </div>
                </div>
                <button type="submit" class="main-btn">Subscribe</button>
            </form>
            <a class="back-btn" *ngIf="!fromHomepage" (click)="closeDialog()">Back Home</a>
        </div>
    </div>

    <div class="dialog-body" *ngIf="subData.subscribeData.response.statusCode == 201">
        <img class="success-icon" class="calendar-icon" src="https://cdn-images.island-conference.com/homepage/tick.svg">
        <h2>Thank you for subscribing!</h2>
        <hr>
        <h3>Stay tuned to get updates on what Island Conference 2024 has to offer!</h3>
    </div>
</div>