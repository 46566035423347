import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(items: any[], property: string, order: string): any[] {
    if (!items || items.length <= 1) {
      return items;
    }

    if (order === 'desc') {
      return items.sort((a, b) => a[property] - b[property]).reverse();
    } else {
      return items.sort((a, b) => a[property] - b[property]);
    }

    // Sort the items by the specified property
    // return items.sort((a, b) => a[property] - b[property]);
  }
}