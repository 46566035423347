<div class="heading" [ngClass]="heading_class">
    <div class="gradient"></div>
    <div id="elements_bgr" [ngClass]="heading_class"></div>
    <div class="container">
        <div class="left">
            <h1>
                <span>{{heading_phrase}}</span><br>
                {{heading_title1}}<br>
                {{heading_title2}}<br>
                {{heading_title3}}
            </h1>
        </div>
        <div class="right">
            <div class="logo-header">
                <img class="logo" src="https://cdn-images.island-conference.com/pageheader/header_logo.png">
            </div>
            <div class="haze_nexxie_logos">
                <div><img src="https://cdn-images.island-conference.com/vision/haze_logo_white.svg"/></div>
                <div><img src="https://cdn-images.island-conference.com/mykonos/nexxie.svg" alt="Nexxie Group company logo in white text"/></div>
            </div>
        </div>
    </div>
</div>