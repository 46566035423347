import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeDialogComponent } from '../components/subscribe-dialog/subscribe-dialog.component';
import { SubscribeDialogService } from '../..//core/_services/subscribe-dialog/subscribe-dialog.service';

@Component({
  selector: 'app-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss']
})
export class HeaderLandingComponent implements OnInit {

  @Input() screenclass: string = '';

  constructor(private dialog: MatDialog,
    private subscribeService: SubscribeDialogService) { }

  ngOnInit(): void {
  }

  openDialog() {
    this.subscribeService.openDialog(true, false);
	}

}
