import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PrivacyPolicyComponent } from '../footer/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '../footer/terms-and-conditions/terms-and-conditions.component';
import { PlatformService } from '@i-con/shared/common';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() screenclass: string = '';

  constructor(
    public dialog: MatDialog,
    private _platformService: PlatformService,
    private activeRoute: ActivatedRoute,
    private router: Router,
  ) {}

  mobileApp: boolean = false;
  visible: boolean = false;
  

  ngOnInit(): void {

    var self = this;

    if (this._platformService.isBrowser) {
      $(window).on('scroll', function () {
        let scrollTop =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
        $('.corner-img').css('opacity', 1 - scrollTop / 100);
      });
    }

    this.activeRoute.queryParams.subscribe((param) => {
      if (param['menu'] == 'off') {
        this.mobileApp = true;
      }
    });
  }

  setClassName() {}

  openTerms() {
    event?.preventDefault();
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  openPrivacy() {
    event?.preventDefault();
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  downloadApp() {
    this.router.navigate(['/download-app']);
  }

  toggleDropDown(target: string) {
    // close the opened list if they are any
    $('.dropdown_list_mobile').each((index: any, item: HTMLElement) => {
      if (item.classList.contains('opened') && item.classList[1] != target) {
        item.classList.remove('opened');
      }
    });

    // rotate the arrow of the opened dropdown if there is any opened
    $('.dropdown-icon').each((index: any, item: HTMLElement) => {
      if (item.classList.contains('opened') && item.classList[1] != target) {
        item.classList.remove('opened');
      }
    });

    // opened the list with the choices
    $('.dropdown-icon.' + target).toggleClass('opened');
    $('.dropdown_list_mobile.' + target).toggleClass('opened');
  }

  toggleSidebar() {
    this.visible = !this.visible;
    $('body').toggleClass('frozen');
  }
}
