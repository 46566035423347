import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { ITicketBundleTypeResponse } from '../../_models/responses/ticket-type-bundle-response.interface';
import { ITicketBundleType } from '../../_models/ticket-bundle.interface';
import { EnvironmentService, StateService } from '@i-con/shared/common';

const TRST_TICKETSBUNDLEATTEND = makeStateKey('tickets_bundle_attend');
interface ITicketBundleState {
  data: ITicketBundleType[];
}

const initialStateValue: any = {
  data: [],
};

@Injectable({ providedIn: 'root' })
export class TicketBundleService extends StateService<ITicketBundleState> {
  data$: Observable<ITicketBundleType[]> = this.getState(state => state.data);
  constructor(private http: HttpClient, private transferState: TransferState, private _environment: EnvironmentService) {
    super(initialStateValue);
    this.getTicketsBundlesAttend();
  }

  getTicketsBundlesAttend(): void {
    const state_data = this.transferState.get(TRST_TICKETSBUNDLEATTEND, null as any);
    if (state_data) {
      this.setState({ data: state_data });
    } else {
      this.http.get<ITicketBundleTypeResponse>(`${this._environment.getApiUrl}/inventory/ticket-bundle`).subscribe(res => {
        this.setState({ data: res.response.data });
        this.transferState.set(TRST_TICKETSBUNDLEATTEND, res.response.data as any);
      });
    }
  }

  getTicketBundles(): Observable<ITicketBundleTypeResponse> {
    return this.http.get<ITicketBundleTypeResponse>(`${this._environment.getApiUrl}/inventory/ticket-bundle`);
  }

  getTicketBundlesByTicketId(id: string): Observable<ITicketBundleTypeResponse> {
    return this.http.get<ITicketBundleTypeResponse>(`${this._environment.getApiUrl}/inventory/ticket-bundle/ticket-type/${+id}`);
  }
}
