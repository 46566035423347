import { Component, Input, Output, EventEmitter } from '@angular/core';
import { cardsData } from './data/successful-api-call.data';
import { INextSteps, ISubText } from './interface/successful-api-call.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'successful-api-call',
  templateUrl: './successful-api-call.component.html',
  styleUrl: './successful-api-call.component.scss',
})
export class SuccessfulApiCallComponent {
  @Input() heading: string;
  @Input() subText: ISubText;
  @Input() redirect: boolean = false;
  @Input() newPath: string = '';

  @Output() goBackInitialPage = new EventEmitter();

  cardsData: INextSteps[] = cardsData;

  constructor(
    private router: Router
  ){

  }

  goBack() {
    if (this.redirect) {
      this.router.navigate([this.newPath])
    }
    else {
      this.goBackInitialPage.emit({step: 1});
    }
  }
}
