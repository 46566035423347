<!-- <app-header></app-header> -->
<app-header-landing></app-header-landing>
<div class="main-page">
    <div class="container">
        <h2>404</h2>
        <h1>Page Not Found</h1>
        <h3>We can't find the page you are looking for!</h3>
        <app-nav-button class="main-btn" [new_path]="['/']" [text]="'Back To Homepage'" [target]="'_self'"></app-nav-button>       
    </div>
</div>
<app-footer screenclass="pageNotFound"></app-footer>
