import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { ITicketTypeResponse } from '../../_models/responses/ticket-type-response.interface';
import { ITicketType } from '../../_models/ticket.interface';
import { AuthorizationService, EnvironmentService, IOrderResponse, StateService } from '@i-con/shared/common';

const TRST_TICKETSATTEND = makeStateKey('tickets_attend');

interface ITicketState {
	data: ITicketType[];
	postData: any;
}

export enum BookingStatus {
	INCOMPLETE,
	COMPLETE,
}

export interface IBookingPostResponse {
	status: number;
	response: {
		code: number;
		statusCode: number;
		error: string;
		data: any;
		message: Array<string> | null | undefined;
	};
}

const initialStateValue: any = {
	data: [],
	postData: {},
};

@Injectable({ providedIn: 'root' })
export class TicketService extends StateService<ITicketState> {
	data$: Observable<ITicketType[]> = this.getState((state) => state.data);
	postData$: Observable<IBookingPostResponse> = this.getState((state) => state.postData);
	constructor(private http: HttpClient,
		private transferState: TransferState,
		private _environment: EnvironmentService,
		private authService: AuthorizationService
		) {
		super(initialStateValue);
		this.getTicketsAttend();
	}

	getTicketsAttend(): void {
		const state_data = this.transferState.get(TRST_TICKETSATTEND, null as any);
		if (state_data) {
			this.setState({ data: state_data });
		}
		else {
			this.http.get<ITicketTypeResponse>(`${this._environment.getApiUrl}/inventory/ticket`).subscribe((res) => {
				this.setState({ data: res.response.data });
				this.transferState.set(TRST_TICKETSATTEND, res.response.data as any);
			});
		}
	}

	getTickets(): Observable<ITicketTypeResponse> {
		return this.http.get<ITicketTypeResponse>(`${this._environment.getApiUrl}/inventory/ticket`);
	}

	clearPostDataState() {
		this.setState({ postData: '' });
	}

	reserveTickets(reservationData: object): void {
		this.http.post(`${this._environment.getApiUrl}/booking/bundle-tickets`, reservationData).subscribe({
			error: (error: any) => {
				this.setState({ postData: error.error });
			},
			next: (response: any) => {
				this.setState({ postData: response });
			},
		});
	}

	reserveLoginTickets(reservationData: object): void {
		this.http.post<IOrderResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/order/ticket`, reservationData, {
			withCredentials: true
		}).subscribe({
			error: (error: any) => {
				this.setState({ postData: error.error });
			},
			next: (response: any) => {
				this.setState({ postData: response });
			},
		});
	}
}
