import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, map } from 'rxjs';

import { IPurchaseTicketForm } from '../../../core/_models/purchase-ticket-form.interface';
import { PurchaseTicketFormGroupService } from '../../../core/_services/tickets/purchase-ticket-form-group/purchaseTicketFormGroup.service';
import { SelectPaymentMethodFormComponent } from '../select-payment-method-form/select-payment-method-form.component';
import { IBookingPostResponse, TicketService } from '../../../core/_services/tickets/ticket.service';
import { Location } from '@angular/common';
import { AuthorizationService, IFormField, IProfileType, ProfileService } from '@i-con/shared/common';
import { PlatformService } from '@i-con/shared/common';
declare let window: any;
@Component({
  selector: 'i-con-purchase-ticket-form-group',
  templateUrl: './purchase-ticket-form-group.component.html',
  styleUrl: './purchase-ticket-form-group.component.scss',
})
export class PurchaseTicketFormGroupComponent implements OnInit {
  @ViewChild(SelectPaymentMethodFormComponent) paymentForm: SelectPaymentMethodFormComponent;

  @Input() ticketId: number;
  @Input() quantity: number;
  @Input() ticketName: string;
  @Input() confirmationName: string = "";
  @Output() setTicketReservation = new EventEmitter();
  response$: Observable<IBookingPostResponse>;
  inTransaction: boolean = false;
  inTransaction$: Observable<boolean>;
  success: boolean = false;
  success$: Observable<boolean>;
  securionError = {
    code: '',
    message: '',
    type: '',
  };

  isLoggedIn: boolean = false;

  constructor(
    private purchaseTicketFormGroupService: PurchaseTicketFormGroupService,
    private platformService: PlatformService,
    private ticketService: TicketService,
    private location: Location,
    private profileService: ProfileService,
    private authService: AuthorizationService
  ) { }

  fieldsDataPart1: IFormField[] = [
    {
      controlName: 'firstName',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'First Name',
      fullLine: false,
      validations: {
        required: true,
        maxLength: 30,
        minLength: 3,
      },
    },
    {
      controlName: 'lastName',
      fieldType: 'text',
      class: 'input-field',
      placeHolder: 'Last Name',
      fullLine: false,
      value: '',
      validations: {
        required: true,
        maxLength: 30,
        minLength: 2,
      },
    },
    {
      controlName: 'email',
      fieldType: 'text',
      class: 'input-field',
      placeHolder: 'Email Address',
      fullLine: true,
      value: '',
      validations: {
        required: true,
        email: true,
      },
    },
  ];

  fieldsDataPart2: IFormField[] = [
    {
      controlName: 'address',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'Address',
      fullLine: false,
      validations: {
        required: true,
        maxLength: 255,
        minLength: 1,
      },
    },
    {
      controlName: 'country',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'Country',
      fullLine: false,
      validations: {
        required: true,
        maxLength: 255,
        minLength: 1,
      },
    },
    {
      controlName: 'city',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'City',
      fullLine: false,
      validations: {
        required: true,
        maxLength: 255,
        minLength: 1,
      },
    },
    {
      controlName: 'zip',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'Zip/Postal Code',
      fullLine: false,
      validations: {
        required: true,
        maxLength: 255,
        minLength: 2,
      },
    },
    {
      controlName: 'company',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'Company Name',
      fullLine: false,
      validations: {
        required: true,
        maxLength: 255,
        minLength: 1,
      },
    },
    {
      controlName: 'vat',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'VAT Number (if applicable)',
      fullLine: false,
      validations: {
        maxLength: 255,
        minLength: 2,
      },
    },
  ];

  fieldsDataPart4: IFormField[] = [
    {
      controlName: 'cardholderName',
      fieldType: 'text',
      class: 'input-field',
      value: '',
      placeHolder: 'Cardholder Name',
      fullLine: true,
      validations: {
        required: true,
        maxLength: 255,
        minLength: 3,
      },
    },
  ];

  fieldsDataPart3: IFormField[] = [
    {
      controlName: 'terms',
      fieldType: 'checkbox',
      class: 'input-field',
      value: true,
      placeHolder: 'Your agreement',
      fullLine: true,
      validations: {
        requiredTrue: true,
      },
    },
    {
      controlName: 'consent',
      fieldType: 'checkbox',
      class: 'input-field',
      value: true,
      placeHolder: 'Optin Consent',
      fullLine: true,
      validations: {},
    },
  ];
  submitted$: Observable<boolean>;
  submitted: boolean;

  purchaseTicketFormGroup: FormGroup;

  conversionEvents: Map<number, string>;

  profileData$!: Observable<IProfileType>;

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();

    this.purchaseTicketFormGroup = new FormGroup({});

    this.submitted$ = this.purchaseTicketFormGroupService.submitted$;
    this.inTransaction$ = this.purchaseTicketFormGroupService.inTransaction$;
    this.success$ = this.purchaseTicketFormGroupService.success$;
    this.response$ = this.ticketService.postData$.pipe(
      map(res => {
        if (res != null) {
          if (res.response != null) {
            if (!Array.isArray(res.response.message) && res.response.message != null) {
              const message = res.response.message;
              res.response.message = [message];
            }
          }
        }
        return res;
      })
    );

    this.profileData$ = this.profileService.profileData$;
		// this.profileService.getProfileData();

    this.submitted$.subscribe(res => {
      this.submitted = res;
    });


    this.inTransaction$.subscribe(res => {
      this.inTransaction = res;
    });

    this.success$.subscribe(res => {
      this.success = res;
    });

    this.purchaseTicketFormGroup = new FormGroup({});
    this.conversionEvents = new Map<number, string>([
      [1, "AW-10940770656/I6jeCPy6zoYYEODS--Ao"],
      [2, "AW-10940770656/45AnCPic8YwZEODS--Ao"],
      [3, "AW-10940770656/0WvkCPuc8YwZEODS--Ao"],
      [5, "AW-10940770656/doNoCP6c8YwZEODS--Ao"]
    ]);

  }

  isValid() {
    return this.purchaseTicketFormGroup.valid && this.paymentForm.isValidForm();
  }

  setPurchaseTicketForm($event: any) {

    if (this.authService.isLoggedIn()) {
      const { purchaseTicketForm, tokenRequest } = $event;

      const payload = {
        cardholder: {
          fullname: purchaseTicketForm.cardholderName,
        },
        transaction: {
          tokenId: tokenRequest.tokenId,
          requireEnrolledCard: tokenRequest.requireEnrolledCard,
          requireSuccessfulLiabilityShift: tokenRequest.requireSuccessfulLiabilityShift,
        },
        reservation: {
          ticketTypes: [{
            ticketTypeId: this.ticketId,
            quantity: this.quantity
          }]
        },
      }

      this.ticketService.reserveLoginTickets(payload);

      this.response$.subscribe(res => {
        if (res.status === 201) {
          this.purchaseTicketFormGroupService.setSuccess(true);
          this.purchaseTicketFormGroupService.setInTransaction(false);
          if (this.platformService.isBrowser) {
            window?.gtag('event', 'conversion', {
              "send_to": this.conversionEvents.get(this.quantity),
              'transaction_id': '',
            });

            window?.fbq('trackCustom', 'TicketPurchase', {
              content_category: 'tickets',
              content_ids: [payload.reservation.ticketTypes[0]?.ticketTypeId],
              content_name: this.ticketName,
              content_type: 'product',
              contents: [{'id': payload.reservation.ticketTypes[0]?.ticketTypeId, 'quantity': payload.reservation.ticketTypes[0]?.quantity}]
            });
          }
        } else {
          this.purchaseTicketFormGroupService.setSuccess(false);
          this.purchaseTicketFormGroupService.setInTransaction(false);
        }
      });
    }
    else {

      const { purchaseTicketForm, tokenRequest } = $event;
      const payload = {
        cardholder: {
          fullname: purchaseTicketForm.cardholderName,
        },
        billing: {
          address: purchaseTicketForm.address,
          country: purchaseTicketForm.country,
          city: purchaseTicketForm.city,
          postalCode: purchaseTicketForm.postalCode,
          companyName: purchaseTicketForm.companyName,
          vatNumber: purchaseTicketForm.vatNumber,
        },
        contact: {
          name: purchaseTicketForm.name,
          surname: purchaseTicketForm.surname,
          email: (purchaseTicketForm.email as string).toLowerCase(),
          termsAgreement: purchaseTicketForm.termsAgreement,
          optinConsent: purchaseTicketForm.optinConsent,
        },
        transaction: {
          tokenId: tokenRequest.tokenId,
          requireEnrolledCard: tokenRequest.requireEnrolledCard,
          requireSuccessfulLiabilityShift: tokenRequest.requireSuccessfulLiabilityShift,
        },
        reservation: {
          ticketTypes: [{
            ticketTypeId: this.ticketId,
            quantity: this.quantity
          }]
        },
      }

      this.ticketService.reserveTickets(payload);

      this.response$.subscribe(res => {
        if (res.status === 201) {
          this.purchaseTicketFormGroupService.setSuccess(true);
          this.purchaseTicketFormGroupService.setInTransaction(false);
          if (this.platformService.isBrowser) {
            window?.gtag('event', 'conversion', {
              "send_to": this.conversionEvents.get(this.quantity),
              'transaction_id': '',
            });

            window?.fbq('trackCustom', 'TicketPurchase', {
              content_category: 'tickets',
              content_ids: [payload.reservation.ticketTypes[0]?.ticketTypeId],
              content_name: this.ticketName,
              content_type: 'product',
              contents: [{'id': payload.reservation.ticketTypes[0]?.ticketTypeId, 'quantity': payload.reservation.ticketTypes[0]?.quantity}]
            });
          }
        } else {
          this.purchaseTicketFormGroupService.setSuccess(false);
          this.purchaseTicketFormGroupService.setInTransaction(false);
        }
      });

    }
  }


  setPaymentError($event: any) {
    const { error } = $event;
    Object.assign(this.securionError, error);
  }

  goBack() {
    this.location.back();
  }


  submitForm() {
    this.purchaseTicketFormGroupService.setSubmitted(true);
    this.purchaseTicketFormGroupService.setInTransaction(true);
    if (this.purchaseTicketFormGroup.valid && this.paymentForm.isValidForm()) {

      if(this.isLoggedIn){
        const purchaseTicketForm : IPurchaseTicketForm = {
          cardholderName: this.purchaseTicketFormGroup.value.part4payment.part4[0],
          termsAgreement: this.purchaseTicketFormGroup.value.part3terms.part3[0],
          optinConsent: this.purchaseTicketFormGroup.value.part3terms.part3[1],
        };
        this.paymentForm.submit(purchaseTicketForm);
      } else {
        const purchaseTicketForm: IPurchaseTicketForm = {
          name: this.purchaseTicketFormGroup.value.part1booking.part1[0],
          surname: this.purchaseTicketFormGroup.value.part1booking.part1[1],
          email: this.purchaseTicketFormGroup.value.part1booking.part1[2],
  
          address: this.purchaseTicketFormGroup.value.part2billing.part2[0],
          country: this.purchaseTicketFormGroup.value.part2billing.part2[1],
          city: this.purchaseTicketFormGroup.value.part2billing.part2[2],
          postalCode: this.purchaseTicketFormGroup.value.part2billing.part2[3],
          companyName: this.purchaseTicketFormGroup.value.part2billing.part2[4],
          vatNumber: this.purchaseTicketFormGroup.value.part2billing.part2[5],
  
          cardholderName: this.purchaseTicketFormGroup.value.part4payment.part4[0],
  
          termsAgreement: this.purchaseTicketFormGroup.value.part3terms.part3[0],
          optinConsent: this.purchaseTicketFormGroup.value.part3terms.part3[1],
        };
        this.paymentForm.submit(purchaseTicketForm);
      }


    } else {
      this.purchaseTicketFormGroupService.setSubmitted(false);
      this.purchaseTicketFormGroupService.setInTransaction(false);
      this.purchaseTicketFormGroupService.setSuccess(false);
      return;
    }
  }
}
