<div class="header-section" [ngClass]="screenclass">
	<div class="navbar flexr">
		<div class="logo-container">
			<a [routerLink]="['/']" (click)="setClassName()">
				<img class="logo-icon" src="https://cdn-images.island-conference.com/header/logo.png"
					alt="White i-Con logo with orange colouring and grey background" />
			</a>
		</div>

		<div class="navbar-container">
			<ul class="navbar-list flexr">
				<li class="nav_item our_vision">
					<a [routerLink]="['/vision']">OUR VISION <img class="arrow"
							src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
							alt="White dropdown arrow pointing down" /></a>
					<div class="items-container">
						<div class="dropdown_items">
							<div class="top-arrow"></div>
							<ul class="dropdown_list">
								<li>
									<a class="nav-link" [routerLink]="['/vision/icon-recap/albums/photo-albums']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/icon-black.svg" alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/icon-green.svg" alt="">
										i-Con 2023 Recap
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/vision']" fragment="nexxie">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/nexxie-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/nexxie-green.svg"
											alt="">
										Nexxie Group
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/vision']" fragment="haze">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/haze-black.svg" alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/haze-green.svg" alt="">
										Haze Team
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/vision']" fragment="cod">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/cod-black.svg" alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/cod-green.svg" alt="">
										City Of Dreams
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/vision']" fragment="merch">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/merch-icon-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/merch-icon-green.svg"
											alt="">
										Merchandise
									</a>
								</li>


							</ul>
						</div>
					</div>
				</li>
				<li class="nav_item attent">
					<a [routerLink]="['/attend']">ATTEND<img class="arrow"
							src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
							alt="White dropdown arrow pointing down" /></a>
					<div class="items-container">
						<div class="dropdown_items">
							<div class="top-arrow"></div>
							<ul class="dropdown_list">
								<li>
									<a class="nav-link" [routerLink]="['/attend/tickets']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/ticket-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/ticket-green.svg"
											alt="">
										Tickets
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/attend/booths']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/exhibit-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/exhibit-green.svg"
											alt="">
										Booths
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/attend/sponsorships']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/sponsorships-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/sponsorships-green.svg"
											alt="">
										Sponsorships
									</a>
								</li>

								<li>
									<a class="nav-link" [routerLink]="['/attend/exhibitors']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/exhibitors-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/exhibitors-green.svg"
											alt="">
										Exhibitors
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/attend/floor-map']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/floor-map-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/floor-map-green.svg"
											alt="">
										Floor Map
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/attend/winning-pitch']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/winning-pitch-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/winning-pitch-green.svg"
											alt="">
										Winning Pitch
									</a>
								</li>
							</ul>
						</div>
					</div>
				</li>
				<li class="nav_item plan">
					<a [routerLink]="['/plan']">PLAN<img class="arrow"
							src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
							alt="White dropdown arrow pointing down" /></a>

					<div class="items-container">
						<div class="dropdown_items">
							<div class="top-arrow"></div>
							<ul class="dropdown_list">
								<li>
									<a class="nav-link" [routerLink]="['/plan/speakers']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/speakers-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/speakers-green.svg"
											alt="">
										Speakers
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/plan/schedule']">

										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/schedule-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/schedule-green.svg"
											alt="">

										Schedule
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/plan/events']">

										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/events-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/events-green.svg"
											alt="">

										Haze Events
									</a>
								</li>
							</ul>
						</div>
					</div>
				</li>
				<li class="nav_item stay">
					<a [routerLink]="['/stay']">STAY<img class="arrow"
							src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
							alt="White dropdown arrow pointing down" /></a>
					<div class="items-container">
						<div class="dropdown_items">
							<div class="top-arrow"></div>
							<ul class="dropdown_list">
								<li>
									<a class="nav-link" [routerLink]="['/stay/accommodation']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/hotels-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/hotels-green.svg"
											alt="">
										Hotels
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/stay/transportation']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/transportation-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/transportation-green.svg"
											alt="">

										Transportation
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/stay/activities']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/activities-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/activities-green.svg"
											alt="">
										Activities
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/stay/care']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/we-care-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/we-care-green.svg"
											alt="">
										We Care
									</a>
								</li>
							</ul>
						</div>
					</div>
				</li>
				<li class="nav_item meetups">
					<a [routerLink]="['/meetups']" style="display: block">MEETUPS<img class="arrow"
							src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
							alt="White dropdown arrow pointing down" /></a>
					<div class="items-container">
						<div class="dropdown_items">
							<div class="top-arrow"></div>
							<ul class="dropdown_list">
								<li>
									<a class="nav-link" [routerLink]="['/meetups/fan-sites']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/fan-site-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/fan-site-green.svg"
											alt="">
										Fan Sites
									</a>
								</li>

								<li>
									<a class="nav-link" [routerLink]="['/meetups/seo-workshops']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/seo_workshops-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/seo_workshops-green.svg"
											alt="">
										SEO Workshops
									</a>
								</li>
								<li>
									<a class="nav-link" [routerLink]="['/miconos']">
										<img class="icon-black"
											src="https://cdn-images.island-conference.com/header/miconos-logo-black.svg"
											alt="">

										<img class="icon-green"
											src="https://cdn-images.island-conference.com/header/miconos-logo-green.svg"
											alt="">

										<img class="icon-black mykonos"
											src="https://cdn-images.island-conference.com/header/mykonos_icon-black.svg"
											alt="">

										<img class="icon-green mykonos"
											src="https://cdn-images.island-conference.com/header/mykonos_icon-green.svg"
											alt="">
									</a>
								</li>
							</ul>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div class="buttons flexr">
			<!-- <app-nav-button class="main-btn" [class]="'count-btn'" [new_path]="['/attend']" [text]="'COUNT ME IN'"
				[type]="'internal'"></app-nav-button> -->


			<div class="download-container" (click)="downloadApp()">
				<img class="apple-logo" src="https://cdn-images.island-conference.com/header/apple.svg">
				<p>Download App</p>
				<img class="android-logo" src="https://cdn-images.island-conference.com/header/android.svg">
			</div>
			<app-modal-button  class="main-btn green" [class]="'count-btn'" [text]="'MY PORTAL'"></app-modal-button>
			<app-nav-button class="main-btn" [class]="'count-btn'" [new_path]="['/attend/tickets']"
				[text]="'PURCHASE TICKETS'" [type]="'internal'" [disabled]="true"></app-nav-button>

		</div>
	</div>

	<div class="menu-items" [ngClass]="{ 'mobile-app' : mobileApp }" [ngClass]="{ 'opened' : visible }"
		(click)="toggleSidebar()">
		<span class="bar bar-one" [ngClass]="{ 'opened' : visible }"></span>
		<span class="bar bar-two" [ngClass]="{ 'opened' : visible }"></span>
		<span class="bar bar-three" [ngClass]="{ 'opened' : visible }"></span>
	</div>

	<div class="sidebar" [ngClass]="{ 'opened' : visible }">
		<div class="top">
			<div class="wrapper">
				<div class="logo-container" (click)="setClassName()">
					<a [routerLink]="['/']" (click)="toggleSidebar()"><img class="logo"
							src="https://cdn-images.island-conference.com/header/logo-mobile.svg"
							alt="White i-Con logo with orange colouring and grey background" /></a>
				</div>
				<div class="items-container">
					<app-modal-button  class="main-btn portal-mobile" [class]="'count-btn'" [text]="'MY PORTAL'"></app-modal-button>
					<app-nav-button class="main-btn" [class]="'count-btn sidebar-btn'" [new_path]="['/attend/tickets']"
						[text]="'PURCHASE TICKETS'" [type]="'internal'" (click)="toggleSidebar()" [disabled]="true"></app-nav-button>
					<div class="download-container" (click)="downloadApp()">
						<img class="apple-logo" src="https://cdn-images.island-conference.com/header/apple.svg">
						<p>Download App</p>
						<img class="android-logo" src="https://cdn-images.island-conference.com/header/android.svg">
					</div>
					<ul class="mobile-items">
						<li class="mobile-item">
							<a [routerLink]="['/vision']" (click)="toggleSidebar()">Our vision</a>
							<div class="dropdown-container">
								<img class="dropdown-icon vision"
									src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
									alt="White dropdown arrow pointing down" (click)="toggleDropDown('vision')" />
							</div>
						</li>
						<div class="dropdown-items vision">
							<ul class="dropdown_list_mobile vision">
								<li class="dropdown-item">
									<a [routerLink]="['/vision/icon-recap/albums/photo-albums']" fragment="i-con"
										(click)="toggleSidebar()"><img class="list-icon"
											src="https://cdn-images.island-conference.com/header/icon.svg"
											alt="i-Con logo with white lining" />i-Con 2023 Recap</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/vision']" fragment="nexxie" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/nexxie.svg"
											alt="Nexxie logo with white lining" />Nexxie Group</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/vision']" fragment="haze" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/haze.svg"
											alt="White round icon which is part of Haze events logo" />Haze Team</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/vision']" fragment="cod" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/cod.svg"
											alt="City Of Dreams Hotel Logo. Text is in white colour" />City of
										Dreams</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/vision']" fragment="merch" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/merch-icon.svg"
											alt="White t-shirt for Merchandise." />Merchandise</a>
								</li>
							</ul>
						</div>
						<li class="mobile-item">
							<a [routerLink]="['/attend']" (click)="toggleSidebar()">Attend</a>
							<div class="dropdown-container" (click)="toggleDropDown('attend')">
								<img class="dropdown-icon attend"
									src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
									alt="White dropdown arrow pointing down" />
							</div>
						</li>
						<div class="dropdown-items attend">
							<ul class="dropdown_list_mobile attend">
								<li class="dropdown-item">
									<a [routerLink]="['/attend/tickets']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/ticket.svg"
											alt="Ticket icon with white lining" />Tickets</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/attend/booths']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/exhibit.svg"
											alt="Booth icon with white lining" />Booths</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/attend/sponsorships']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/sponsorships.svg"
											alt="Sponsorship icon with white lining" />Sponsorships</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/attend/exhibitors']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/exhibitors.svg"
											alt="Icon of three people. The icon has white lining" />Exhibitors</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/attend/floor-map']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/floor-map.svg"
											alt="The icon has white lining" />Floor Map</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/attend/winning-pitch']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/winning-pitch.svg"
											alt="Winning pitch icon displaying a white rocket" />Winning Pitch</a>
								</li>
							</ul>
						</div>
						<li class="mobile-item">
							<a [routerLink]="['/plan']" (click)="toggleSidebar()">Plan</a>
							<div class=" dropdown-container" (click)="toggleDropDown('plan')">
								<img class="dropdown-icon plan"
									src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
									alt="White dropdown arrow pointing down" />
							</div>
						</li>
						<div class="dropdown-items plan">
							<ul class="dropdown_list_mobile plan">
								<li class="dropdown-item">
									<a [routerLink]="['/plan/speakers']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/speakers.svg"
											alt="Speaker icon with white lining" />Speakers</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/plan/schedule']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/schedule.svg"
											alt="Schedule icon with white lining" />Schedule</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/plan/events']" (click)="toggleSidebar()"><img class="list-icon"
											src="https://cdn-images.island-conference.com/header/events.svg"
											alt="White coloured events icon" />Haze Events</a>
								</li>
							</ul>
						</div>
						<li class="mobile-item">
							<a [routerLink]="['/stay']" (click)="toggleSidebar()">Stay</a>
							<div class=" dropdown-container" (click)="toggleDropDown('stay')">
								<img class="dropdown-icon stay"
									src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
									alt="White dropdown arrow pointing down" />
							</div>
						</li>
						<div class="dropdown-items stay">
							<ul class="dropdown_list_mobile stay">
								<li class="dropdown-item">
									<a [routerLink]="['/stay/accommodation']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/hotels.svg"
											alt="Hotels icon with white lining" />Hotels</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/stay/transportation']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/transportation.svg"
											alt="Transportation icon with white lining" />Transportation</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/stay/activities']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/activities.svg"
											alt="White coloured icon for activities" />Activities</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/stay/care']" (click)="toggleSidebar()"><img class="list-icon"
											src="https://cdn-images.island-conference.com/header/we-care.svg"
											alt="White coloured we care icon" />We Care</a>
								</li>
							</ul>
						</div>
						<li class="mobile-item">
							<a [routerLink]="['/meetups']" (click)="toggleSidebar()">Meetups</a>
							<div class=" dropdown-container" (click)="toggleDropDown('meetups')">
								<img class="dropdown-icon meetups"
									src="https://cdn-images.island-conference.com/header/dropdown_arrow.svg"
									alt="White dropdown arrow pointing down" />
							</div>
						</li>
						<div class="dropdown-items meetups">
							<ul class="dropdown_list_mobile meetups">
								<li class="dropdown-item">
									<a [routerLink]="['/meetups/fan-sites']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/fan-site.svg"
											alt="Fan Sites icon with white lining" />Fan Sites</a>
								</li>
								<!-- <li class="dropdown-item">
									<a [routerLink]="['/meetups/workshops']" (click)="toggleSidebar()"><img class="list-icon"
											src="https://cdn-images.island-conference.com/header/meetups.svg"
											alt="Workshops icon with white lining" />Workshops</a>
								</li> -->
								<li class="dropdown-item">
									<a [routerLink]="['/meetups/seo-workshops']" (click)="toggleSidebar()"><img
											class="list-icon"
											src="https://cdn-images.island-conference.com/header/seo_workshops_white.svg"
											alt="Workshops icon with white lining" />SEO Workshops</a>
								</li>
								<li class="dropdown-item">
									<a [routerLink]="['/miconos']" (click)="toggleSidebar()">
										<img class="list-icon"
											src="https://cdn-images.island-conference.com/header/miconos-logo.svg"
											alt="white coloured logo for miconos" />
										<img class="miconos"
											src="https://cdn-images.island-conference.com/header/mykonos_icon_white.svg"
											alt="White coloured icon for miconos" />
									</a>
								</li>
							</ul>
						</div>
					</ul>
				</div>
			</div>
			<div class="music-player">
				<!-- <native-music-player></native-music-player> -->
				<i-con-native-music-player></i-con-native-music-player>
			</div>
			<!-- <div class="bottom">
				<div class="footer-container">
					<ul class="footer-items">
						<li class="footer-item"><a href="#" (click)="openPrivacy()">Privary Policy</a></li>
						<li class="footer-item"><a href="#" (click)="openTerms()">Terms and Conditions</a></li>
						<li class="footer-item"><a [routerLink]="['/faq']">FAQ</a></li>
						<li class="footer-item"><a [routerLink]="['/media-partners']">Media Partners</a></li>
						<li class="footer-item"><a [routerLink]="['/contact']">Contact Us</a></li>
					</ul>
					<div class="social-container">
						<img class="social-icon fb_icon" src="https://cdn-images.island-conference.com/footer/fb_white.svg" alt="Facebook favicon. Icon and circle border are white" />
						<img class="social-icon insta_icon" src="https://cdn-images.island-conference.com/footer/insta_white.svg" alt="Instagram favicon. Icon and circle border are black and white"/>
						<img class="social-icon linked_icon" src="https://cdn-images.island-conference.com/footer/link_white.svg" alt="LinkedIn favicon. Icon and circle border are black and white"/>
						<img class="social-icon spotify_icon" src="https://cdn-images.island-conference.com/footer/spotify_white.svg" alt="Spotify favicon. Icon and circle border are black and white"/>
					</div>
					<div class="copyright">
						<p>&#169; 2022 Island Conference</p>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</div>
<div class="menu-overlay" [ngClass]="{ 'opened' : visible }" (click)="toggleSidebar()"></div>