import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { AuthorizationService, EnvironmentService } from '@i-con/shared/common';
import { LoginDialogComponent } from '@i-con/shared/ui';

@Component({
    selector: 'app-modal-button',
    templateUrl: './modal-button.component.html',
    styleUrls: ['./modal-button.component.scss']
})
export class ModalButtonComponent implements OnInit {

    @Input() new_path: string[];
    @Input() target: string;
    @Input() text: string;
    @Input() class: string;
    @Input() type: string;
    @Input() queryParams: Params;
    @Input() disabled: boolean;

    isLoggedIn: boolean = false;

    constructor(private dialog: MatDialog,
        private router: Router,
        private authService: AuthorizationService,
        private environment: EnvironmentService
        ) { }

        ngOnInit(): void {
            this.isLoggedIn = this.authService.isLoggedIn();

            if (this.isLoggedIn) {
                this.text = "My Portal"
            }
        }

    openDialog(){

        if (this.isLoggedIn) {
            window.open(this.environment.getPortalUrl, "_blank");
        }
        else {
            this.dialog.open(LoginDialogComponent, {
                maxHeight: '80vh',
                maxWidth: '95vw',
                width: '560px',
                height: '825px',
                autoFocus: false,
            });
        }
    }
}
