import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { StateService } from '@i-con/shared/common';
import { Observable } from 'rxjs';

interface IFormState {
  submitted: boolean;
  inTransaction: boolean;
  success: boolean
}

const initialValue: IFormState = {
  submitted: false,
  inTransaction: false,
  success: false,
};

@Injectable({ providedIn: 'root' })
export class PurchaseTicketFormGroupService extends StateService<IFormState> {
  submitted$: Observable<boolean> = this.getState(state => state.submitted);
  inTransaction$:  Observable<boolean> = this.getState(state => state.inTransaction);
  success$:  Observable<boolean> = this.getState(state => state.success);
  constructor() {
    super(initialValue);
  }

  public checkErrors(formArray: FormArray, error: string, fieldNumber: number): boolean {
    const errors = formArray.controls[fieldNumber].errors;

    if (errors) {
      if (error in errors) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setInTransaction(value: boolean) {
    this.setState({ inTransaction: value});
  }

  setSubmitted(value: boolean) {
    this.setState({ submitted: value });
  }

  setSuccess(value: boolean) {
    this.setState({ success: value });
  }
}
