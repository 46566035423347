import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  externalFooter: Element;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    const footer = this.document.querySelector('i-con-footer');
    if (footer) {
      this.externalFooter = footer;
      this.externalFooter.setAttribute('style', 'display: none;');
    }
  }

  ngOnDestroy(): void {
    if (this.externalFooter) this.externalFooter.removeAttribute('style');
  }
}
