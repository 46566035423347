import { Injectable } from '@angular/core';

interface Script {
	name: string;
	src: string;
}

export const ScriptStore: Script[] = [{ name: 'securion', src: 'https://js.securionpay.com/v2/securionpay.js' }];

@Injectable({
	providedIn: 'root',
})
export class ScriptService {
	private scripts: any = {};
	constructor() {
		ScriptStore.forEach((script: Script) => {
			this.scripts[script.name] = {
				loaded: false,
				src: script.src,
			};
		});
	}

	async load(...scripts: string[]): Promise<any[]> {
		const promises: any[] = [];
		scripts.forEach((script) => promises.push(this.loadScript(script)));
		return Promise.all(promises);
	}

	public unloadScript(name: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const script = document.getElementById(name);
			if (script != null) {
				document.getElementsByTagName('head')[0].removeChild(script);
				this.scripts[name].loaded = false;
				resolve({
					script: name,
					unloaded: true,
					status: 'unloaded',
				});
			} else {
				reject({
					script: name,
					loaded: false,
					status: 'error',
					error: 'Could not find script',
				});
			}
		});
	}

	private loadScript(name: string): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.scripts[name].loaded) {
				resolve({ script: name, loaded: true, status: 'exists' });
			} else {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = this.scripts[name].src;
				script.id = name;
				script.onload = () => {
					this.scripts[name].loaded = true;
					resolve({ script: name, loaded: true, status: 'loaded' });
				};
				script.onerror = (error: any) =>
					resolve({
						script: name,
						loaded: false,
						status: 'error',
						error: error,
					});
				document.getElementsByTagName('head')[0].appendChild(script);
			}
		});
	}
}
