import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-speakers-dynamic-dialog',
  templateUrl: './speakers-dynamic-dialog.component.html',
  styleUrls: []
  //styleUrls: ['./speakers-dynamic-dialog.component.scss']
})
export class SpeakersDynamicDialogComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit(): void {
  }

}

