<div class="form-container" *ngIf="profileData$ | async as profileData">
    <form [formGroup]="purchaseTicketFormGroup" (ngSubmit)="submitForm()" class="form-wrapper">
        <p class="post-error-message"
        *ngFor="let error of (this.response$ | async)?.response?.message; index as i">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            fill="#de1111">
            <path fill="#de1111"
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
        </svg>
        {{ error }}
        </p>

        <ng-container *ngIf="isLoggedIn; else notLoggedin">
            <h3>Thank you for your interest in {{confirmationName}}!</h3>
            <p>Simply fill in your payment information in the form provided below and click on "Confirm Purchase"!</p>
        </ng-container>

        <ng-template #notLoggedin>
            <i-con-booking-form [fieldsDataPart1]="fieldsDataPart1" [submitted]="submitted" [profileDetails]="profileData"></i-con-booking-form>
            <i-con-billing-form [fieldsPart2]="fieldsDataPart2" [submitted]="submitted" [profileDetails]="profileData"></i-con-billing-form>
        </ng-template>

        <i-con-select-payment-method-form [fieldsDataPart4]="fieldsDataPart4" [submitted]="submitted"
            [ticketId]="ticketId" [quantity]="quantity" (setPurchaseTicketForm)="setPurchaseTicketForm($event)" (setPaymentError)="setPaymentError($event)"></i-con-select-payment-method-form>

        <!-- <terms-form [fieldsPart3]="fieldsDataPart3" [submitted]="submitted" [showInformationSection]="true"></terms-form> -->
        <i-con-terms-form [fieldsPart3]="fieldsDataPart3" [submitted]="submitted" [showInformationSection]="true"></i-con-terms-form>

        <div class="buttons">
            <button class="main-btn" (click)="submitForm()" [disabled]="!isValid() || inTransaction">
                <div *ngIf="inTransaction; else isValidText"> 
                    <mat-spinner class="intransaction-spinner" diameter="30"></mat-spinner>
                </div>
                <ng-template #isValidText>Complete Purchase</ng-template>
            </button>
            <button class="main-btn back-btn" (click)="goBack()">Back</button>
        </div>
    </form>
</div>