<div class="speaker-dialog">
    <!-- <h2 mat-dialog-title>{{data?.company}}</h2> -->
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>X</button>
    </mat-dialog-actions>
    <mat-dialog-content class="mat-typography">
        <div class="dialog-header">
            <div class="dialog-top-header flexr">
                <div class="left">
                    <div><img src="{{data?.profile_pic}}" alt="{{data?.profile_pic_alt}}"/></div>
                </div>
                <div class="center">
                    <h3>{{data?.name}} {{data?.surname}}</h3>
                    <h4>{{data?.position}}</h4>
                </div>
                <div class="right">
                    <img src="{{data?.company_logo}}" alt="{{data?.company_logo_alt}}"/>
                </div>
            </div>
            <div class="dialog-mid-header flexr">
                <!-- <p>{{data?.name}} is speaking at</p> -->
                <p>{{data?.speech_label}}</p>
                <h2>{{data?.speech_title}}</h2>
                <p>{{data?.speech_date}} / {{data?.speech_time}} / {{data?.speech_venue}}</p>
            </div>
        </div>
        <div class="dialog-body" *ngIf="data.speech_desc">
            <h3>Description:</h3>
            <p [innerHTML]="data.speech_desc"></p>
        </div>
        <div class="dialog-body" *ngIf="data.bio">
            <h3>About {{data?.name}}:</h3>
            <p [innerHTML]="data.bio"></p>
        </div>
        <div class="dialog-footer flexr">
            <div class="social-media flexr">
                <div class="facebook" *ngIf="data.facebook"><a href="{{data?.facebook}}" target="_blank"><img src="https://cdn-images.island-conference.com/footer/fb_black.svg"/></a></div>
                <div class="instagram" *ngIf="data.instagram"><a href="{{data?.instagram}}" target="_blank"><img src="https://cdn-images.island-conference.com/footer/insta_black.svg"/></a></div>
                <div class="linkedin" *ngIf="data.linkedin"><a href="{{data?.linkedin}}" target="_blank"><img src="https://cdn-images.island-conference.com/footer/link_black.svg"/></a></div>
            </div>
        </div>
    </mat-dialog-content>
</div>