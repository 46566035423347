import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ISubscribeResponse } from '../../../core/_models/responses/subscribe-response.interface';
import { SubscribeDialogService } from '../../../core/_services/subscribe-dialog/subscribe-dialog.service';
interface ISubscribeState {
    subscribeData: ISubscribeResponse
}
@Component({
    selector: 'app-subscribe-dialog',
    templateUrl: './subscribe-dialog.component.html',
    styleUrls: ['./subscribe-dialog.component.scss']
})

export class SubscribeDialogComponent implements OnInit {

    fromHomepage: boolean = false;
    submitted: boolean = false;
    subscribeForm: UntypedFormGroup

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private subscribeDialog: SubscribeDialogService,
    private formBuilder: UntypedFormBuilder,) { }

    subData$: Observable<ISubscribeState>;

    get f() {
        return this.subscribeForm.controls;
    }

    ngOnInit(): void {


        this.subData$ = this.subscribeDialog.subData$;

        if (this.data) {
            this.fromHomepage = this.data;
        }

        this.subscribeForm = this.formBuilder.group({
            emailControl: ['', [Validators.required, Validators.email, Validators.maxLength(255), Validators.minLength(6)]],
        });
    }

    closeDialog() {
        this.subscribeDialog.closeDialog();
    }

    onSubmit(){
        this.submitted = true;

        if (this.subscribeForm.invalid) {
            return;
        }

        const subscribeObject = {
            "email": this.subscribeForm.value.emailControl
        }

        this.subscribeDialog.subscribePost(subscribeObject);
    }
}
