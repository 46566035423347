import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit {

  @Input() heading_class: string = '';
  @Input() heading_phrase: string = '';
  @Input() heading_title1: string = '';
  @Input() heading_title2: string = '';
  @Input() heading_title3: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
