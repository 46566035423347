<div class="header-section" [ngClass]="screenclass">
    <div class="left">
        <div class="logo-container">
			<a [routerLink]="['/']">
				<img class="logo-icon" src="https://cdn-images.island-conference.com/header/logo.png" alt="White i-Con logo with orange colouring and grey background" />
			</a>
		</div>
    </div>
    <div class="right">
        <button class="main-btn" (click)="openDialog()">STAY TUNED</button>
    </div>
</div>