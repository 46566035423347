import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ISubscribeResponse } from "../../_models/responses/subscribe-response.interface";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { SubscribeDialogComponent } from "../../../shared/components/subscribe-dialog/subscribe-dialog.component";
import { EnvironmentService, StateService } from "@i-con/shared/common";

interface ISubscribeState {
    subscribeData: ISubscribeResponse
}

const initailSubValue: ISubscribeState = {
    subscribeData: {
        response: {
            data: {
                email: ''
            },
            statusCode: 0,
            message: [],
        }
    }    
}

@Injectable({ providedIn: 'root' })
export class SubscribeDialogService extends StateService<ISubscribeState>{
    constructor(
        private dialog: MatDialog,
        private http: HttpClient,
        private _environment: EnvironmentService,
        private router: Router
    ) {
        super(initailSubValue)
    }

    subData$: Observable<ISubscribeState> = this.getState((state) => state);
    
    public dialogRef: any;

    openDialog(fromHomepage: boolean, disableClose: boolean){
        this.dialogRef = this.dialog.open(SubscribeDialogComponent, {
            maxHeight: '90vh',
			maxWidth: '95vw',
			width: '500px',
			autoFocus: false,
			data: fromHomepage,
            disableClose: disableClose,
            panelClass: 'newsletter-dialog'
        });
    }

    closeDialog() {
        this.dialogRef.close();
        this.router.navigate(['/']);
    }

    subscribePost(subscribeInfo: object){
        this.http.post<ISubscribeResponse>(`${this._environment.getApiUrl}/stay-tuned`, subscribeInfo).subscribe({
            error: (error: any) => {
				this.setState({ subscribeData: error.error });
			},
			next: (resposnse: any) => {
				this.setState({ subscribeData: resposnse });
			},
        })
    }

}