import { Pipe, PipeTransform } from "@angular/core";
import { IDiscountType } from "../../core/_models/discount-type.interface";

@Pipe({name: 'discountedPrice'})
export class CalculateDiscountedPricePipe implements PipeTransform {
  transform(price: number, discounts: IDiscountType[]): number {
    discounts?.forEach( discount => {
      if(discount.endsOn < new Date() && discount.startsOn > new Date()){
        price = Math.round((price * ( 1 - discount.percentage)/100) * 100);
      }
    });
    return price;
  }
}