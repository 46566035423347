<div class="form-container">
    <div [formGroup]="purchaseTicketForm" class="form-wrapper">
        <div class="part4" formGroupName="part4payment">
            <h3> Payment Details
                <img class="card" src="https://cdn-images.island-conference.com/attend/tickets/visa.svg" />
                <img class="card" src="https://cdn-images.island-conference.com/attend/tickets/mastercard.svg" />
            </h3>

            <p class="error-message" [ngClass]="{ show: this.securionError.message }">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="#de1111">
                    <path fill="#de1111"
                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
                </svg>
                {{ this.securionError.message }}
            </p>

            <div class="field-wrapper">
                <ng-container *ngFor="let field of fieldsDataPart4; let i = index;">
                    <div formArrayName="part4" class="field-container" [ngClass]="{'inline': field.fullLine}">
                        <input [attr.type]="field.fieldType" class="{{field.class}} skeleton" [placeholder]="field.placeHolder"
                            [formControlName]="i">
                        <div class="invalid-feedback" *ngIf="(purchaseTicketForm.touched || submitted)  && f[i].errors">
                            <div *ngIf="checkErrors(i, 'required')">
                                {{field.placeHolder}} is required.
                            </div>
                            <div *ngIf="checkErrors(i, 'email')">
                                Invalid email format.
                            </div>
                            <div *ngIf="checkErrors(i, 'maxlength')">
                                A maximum of
                                {{
                                (purchaseTicketForm.touched || submitted) && field.validations.maxLength
                                }}
                                characters length is required
                            </div>
                            <div *ngIf="checkErrors(i, 'minlength')">
                                A maximum of
                                {{
                                (this.purchaseTicketForm.touched || submitted) && field.validations.minLength
                                }}
                                characters length is required
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="form-row">
                    <div class="form-col card-field">
                        <div data-securionpay="number" id="number" class="skeleton"></div>
                        <div *ngIf="purchaseTicketForm.touched || this.number?._focus || submitted"
                            class="invalid-feedback">
                            <div
                                *ngIf="(purchaseTicketForm.touched || this.number?._focus || submitted) && this.number?._invalid">
                                Card Number is invalid
                            </div>
                            <div
                                *ngIf="(purchaseTicketForm.touched || this.number?._focus || submitted) && this.number?._empty ">
                                Card Number is required
                            </div>
                        </div>
                    </div>

                    <div class="adaptive-container">
                        <div class="form-col expiry-field">
                            <div data-securionpay="expiry" id="expiry" class="skeleton"></div>
                            <div *ngIf="purchaseTicketForm.touched || this.expiry?._focus || submitted"
                                class="invalid-feedback">
                                <div
                                    *ngIf="(purchaseTicketForm.touched || this.expiry?._focus || submitted) && this.expiry?._invalid">
                                    Expiry date is invalid
                                </div>
                                <div
                                    *ngIf="(purchaseTicketForm.touched || this.expiry?._focus || submitted) && this.expiry?._empty">
                                    Expiry date required
                                </div>
                            </div>
                        </div>
                        <div class="form-col cvc-field">
                            <div data-securionpay="cvc" class="skeleton" id="cvc"></div>
                            <div *ngIf="purchaseTicketForm.touched || this.cvc?._focus || submitted"
                                class="invalid-feedback">
                                <div
                                    *ngIf="(purchaseTicketForm.touched || this.cvc?._focus || submitted) && this.cvc?._invalid">
                                    CVC is invalid
                                </div>
                                <div
                                    *ngIf="(purchaseTicketForm.touched || this.cvc?._focus || submitted) && this.cvc?._empty">
                                    CVC is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>