import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, shareReplay } from 'rxjs';


import { ITicketBundleTypeResponse } from '../../_models/responses/ticket-type-bundle-response.interface';
import { ITicketBundleType } from '../../_models/ticket-bundle.interface';
import { TicketBundleService } from './ticket-bundle.service';
import { StateService } from '@i-con/shared/common';

const TRST_BUNDLETICKETS = makeStateKey('tickets_bundle');

interface ITicketBundleState {
  bundle_types: ITicketBundleType[];
  selected_bundle_type: ITicketBundleType;
  selected_bundle_id: string;
}

const initialState: ITicketBundleState = {
  bundle_types: [],
  selected_bundle_type: {
    id: 1,
    name: '',
    label: '',
    description: '',
    ticketQuantity: 0,
    realQuantity: 0,
    ticket: {
      id: 1,
      description: 'a',
      price: 1,
      discounts: [{ id: 1, percentage: 0.15, name: 'name', description: 'desc', startsOn: new Date(), endsOn: new Date() }],
      includes: [{ content: 'a' }],
      inStock: true,
      discount: 0,
      onTheDoor: 0,
      discountEndsOn: new Date(),
      color: ''
    },
    inStock: true,
    metadata: '',
    imageFilename: '',
    discounts: [],
  },
  selected_bundle_id: '',
};

@Injectable({ providedIn: 'root' })
export class TicketBundleStateService extends StateService<ITicketBundleState> {
  public bundle_types$: Observable<ITicketBundleType[]> = this.getState(state => state.bundle_types);
  
  public setBundleTypes() {
    const state_data = this.transferState.get(TRST_BUNDLETICKETS, null as any);
    if (state_data) {
      this.setState({ bundle_types: state_data });
    } else {
      this.ticketBundleService.getTicketBundles().subscribe((res: ITicketBundleTypeResponse) => {
        this.setState({ bundle_types: res.response.data });
        this.transferState.set(TRST_BUNDLETICKETS, res.response.data as any);
      });
    }
  }

  public selected_bundle_type$: Observable<ITicketBundleType> = this.getState(state => state.selected_bundle_type);

  public setSelectedBundleType(selected_bundle_type: ITicketBundleType) {
    this.setState({ selected_bundle_type });
  }

  public selected_bundle_id$: Observable<string> = this.getState(state => state.selected_bundle_id);

  public setSelectedBundleId(selected_bundle_id: string) {
    this.setState({ selected_bundle_id });
  }

  ticket_bundle_state$: Observable<ITicketBundleState> = this.getState(state => state).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  constructor(private readonly ticketBundleService: TicketBundleService, private transferState: TransferState) {
    super(initialState);
    this.setBundleTypes();
  }
}
